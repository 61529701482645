import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import get from 'lodash/get';

// Components
import { FullPageWidth } from '../../components/global/FullPageWidth/FullPageWidth';
import { BillboardBannerAd } from '../../components/global/Ads/BillboardBannerAd';

// Helpers
import { getBillboardAdData } from '../../utils/adHelpers';

const SbSharedBillboardAdComponent = ({ itemSearch, adWidth = 'mobile' }) => {
    const { displayUriRef } = itemSearch;
    const billboardAdData = get(getBillboardAdData(displayUriRef), adWidth);

    if (!billboardAdData) {
        return null;
    }

    return (
        <FullPageWidth>
            <BillboardBannerAd {...billboardAdData} />
        </FullPageWidth>
    );
};

SbSharedBillboardAdComponent.propTypes = {
    itemSearch: PropTypes.object.isRequired,
    adWidth: PropTypes.string.isRequired,
};

export const SbSharedBillboardAd = createFragmentContainer(SbSharedBillboardAdComponent, {
    itemSearch: graphql`
        fragment SbSharedBillboardAd_itemSearch on ItemSearchQueryConnection {
            displayUriRef
        }
    `,
});
