import { lazy, Suspense } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import authModalLoader from 'dibs-buyer-layout/src/utils/AuthModalLoader';

// components
import { SharedFollowTopButton } from '../../shared/SharedFollowTopButton/SharedFollowTopButton';
import { SbRespFollowSearchBannerLazy } from '../SbRespFollowSearchBanner/SbRespFollowSearchBannerLazy';
import { SharedBottomModule } from '../../shared/SharedBottomModule/SharedBottomModule';
import { SbSharedTitle } from '../SbSharedTitle/SbSharedTitle';
import { SbSharedPageBreadcrumbs } from '../SbSharedPageBreadcrumbs/SbSharedPageBreadcrumbs';
import { SbSharedTopTileController } from '../SbSharedTopTileController/SbSharedTopTileController';
import { SbRespCategoryTiles } from '../SbRespCategoryTiles/SbRespCategoryTiles';
import { SbRespDesignTiles } from '../SbRespDesignTiles/SbRespDesignTiles';
import { SbSharedBillboardAd } from '../SbSharedBillboardAd/SbSharedBillboardAd';
import { SbRespNullSearchResults } from '../SbRespNullSearchResults/SbRespNullSearchResults';
import { SbRespInteriorsWordpressArticlesWrapper } from '../SbRespInteriorsModule/SbRespInteriorsWordpressArticlesWrapper';
import { SharedRelatedPages } from '../../shared/SharedRelatedPages/SharedRelatedPages';
import { SbSharedRelatedCreators } from '../SbSharedRelatedCreators/SbSharedRelatedCreators';
import { SharedRespLinksBlock } from '../../shared/SharedRespLinksBlock/SharedRespLinksBlock';
import { SbRespNotificationUnavailableItem } from '../SbRespNotifications/SbRespNotificationUnavailableItem/SbRespNotificationUnavailableItem';
import { SbRespPriceTiles } from '../SbRespPriceTiles/SbRespPriceTiles';
import { TradePromoLoader } from '../../global/TradePromo/TradePromoLoader';
import { SbRespTradeOnApprovalLoadingBanner } from '../SbRespTradeOnApprovalPromo/SbRespTradeOnApprovalPromoBanner/SbRespTradeOnApprovalLoadingBanner';
import { SbSharedPersonalizedFilterToast } from '../SbSharedPersonalizedFilterToast/SbSharedPersonalizedFilterToast';
import { SbRespMainContent } from '../SbRespMainContent/SbRespMainContent';
import { SbSharedForceSearchLink } from '../SbSharedForceSearchLink/SbSharedForceSearchLink';
import { SbRespContentModuleSelector } from '../SbRespContentModuleSelector/SbRespContentModuleSelector';
import { SbSharedControlledHeadingWrapper } from '../SbSharedControlledHeadingWrapper/SbSharedControlledHeadingWrapper';
import { SbSharedRelatedSearches } from '../SbSharedRelatedSearches/SbSharedRelatedSearches';
import { SbRespL1Landing } from '../SbRespL1Landing/SbRespL1Landing';
import { SharedFAQ } from '../../shared/SharedFAQ/SharedFAQ';
import { RecentlyViewedItemsLazy } from 'dibs-recent-history/exports/RecentlyViewedItemsLazy';
import { SbSharedRecommendedItems } from '../SbSharedRecommendedItems/SbSharedRecommendedItems';
import { getFilterValueByUrlLabel } from '../SbSharedRefineMenu/sbSharedRefineMenuHelpers';
import { SharedSaveSearchContextProvider } from '../../shared/SharedSaveSearchContext/SharedSaveSearchContext';
import { locations } from '../../utils/tracking/shared/followSearchTracking';
import { useCurrency } from 'dibs-buyer-layout/exports/useCurrency';

// constants
import { pageTypeConstants as pageTypes } from '../../constants/pageTypeConstants';
import { SEARCH_TYPES, FILTER_SEARCH_TYPE } from '../../constants/sbConstants';
import { FILTER_REFINE_SEARCH } from '../SbSharedRefineMenu/sbSharedRefineMenuConstants';
import {
    MIN_THIN_PAGE_COUNT,
    REVEAL_RELATED_CREATORS_COUNT_DESKTOP,
} from '../../constants/sbConstants';

const { SEARCH } = pageTypes;

// styles
import styles from './SbRespComponents.scss';

const SbRespTradeOnApprovalPromoBannerLazy = lazy(() =>
    import(
        /* webpackChunkName: "TradeOnApprovalPromo" */ '../SbRespTradeOnApprovalPromo/SbRespTradeOnApprovalPromoBanner/SbRespTradeOnApprovalPromoBannerLazy'
    )
);

function getSearchTerm(filters, isSearchPage) {
    const searchKey = isSearchPage ? SEARCH : FILTER_REFINE_SEARCH;

    return get(
        filters.filter(({ name }) => name === searchKey),
        '[0].values[0].urlLabel'
    );
}

function SbRespComponentsComponent({
    viewer,
    itemSearch,
    user,
    generatedUriRef,
    isClient,
    relayVariables,
    shippingFilters,
}) {
    const {
        totalResults,
        appliedFilters,
        pageType,
        internalBuyLinks = [],
        isL1LandingPage,
        bottomModule,
        soldItems,
    } = itemSearch;

    const { isRelatedCreatorsModuleEnabled } = viewer;

    const isSearch = pageType === SEARCH;
    const showNullSearchResultPage = !totalResults;
    const isThinPage = totalResults < MIN_THIN_PAGE_COUNT;
    const searchTerm = getSearchTerm(appliedFilters, isSearch);
    const isFuzzyResults = !!getFilterValueByUrlLabel(
        appliedFilters,
        FILTER_SEARCH_TYPE,
        SEARCH_TYPES.FUZZY
    );

    const { currency } = useCurrency();

    //if has 0 results or it's fuzzy results with sold items (SEARCH-1598)
    if (showNullSearchResultPage || (isFuzzyResults && soldItems?.totalResults)) {
        return (
            <SharedSaveSearchContextProvider
                itemSearch={itemSearch}
                user={user}
                location={locations.SEARCH_BROWSE}
                searchTerm={searchTerm}
                uriRef={generatedUriRef}
            >
                <SbRespNullSearchResults
                    viewer={viewer}
                    itemSearch={itemSearch}
                    user={user}
                    isClient={isClient}
                    searchTerm={searchTerm}
                />
            </SharedSaveSearchContextProvider>
        );
    }

    // Currently search and recognized dealers pages have their saveSearch cta above main items container
    // Buy and designs pages have their saveSearchButton above main items container too, but these pages uses their own SbRespComponents
    const showTopSaveSearchButton = !(
        pageType === pageTypes.SEARCH || pageType === pageTypes.RECOGNIZED_DEALER
    );

    return (
        <SharedSaveSearchContextProvider
            itemSearch={itemSearch}
            user={user}
            location={locations.SEARCH_BROWSE}
            searchTerm={searchTerm}
            uriRef={generatedUriRef}
        >
            {/* Breadcrumbs */}
            {!isSearch && <SbSharedPageBreadcrumbs itemSearch={itemSearch} user={user} />}
            <SbSharedControlledHeadingWrapper itemSearch={itemSearch}>
                {Heading => (
                    <>
                        {isL1LandingPage ? (
                            /* L1 Page Header */
                            <SbRespL1Landing itemSearch={itemSearch} />
                        ) : (
                            /* Page Header */
                            <div className={styles.headerLeftWrapper}>
                                <div className={styles.headerContainer}>
                                    <div className={styles.headerLeft}>
                                        <SbSharedTitle
                                            itemSearch={itemSearch}
                                            user={user}
                                            Heading={Heading}
                                        >
                                            {showTopSaveSearchButton && (
                                                <SharedFollowTopButton
                                                    itemSearch={itemSearch}
                                                    user={user}
                                                    addLeadingSpace
                                                />
                                            )}
                                        </SbSharedTitle>
                                    </div>
                                </div>
                                {relayVariables.isTrade && isClient && (
                                    <div className={styles.tradePromoMessage}>
                                        <TradePromoLoader
                                            showIcon
                                            user={user}
                                            displayInfo={null}
                                            item={null}
                                        />
                                    </div>
                                )}
                                {/* Related Searches */}
                                <SbSharedRelatedSearches itemSearch={itemSearch} />
                            </div>
                        )}

                        {/* Content Module */}
                        <SbRespContentModuleSelector itemSearch={itemSearch} user={user} />

                        <SbSharedForceSearchLink itemSearch={itemSearch} searchTerm={searchTerm} />

                        {/* Broad Search Category Tiles, or, for trade users, Price Range Tiles or On-Approval banner)*/}
                        <SbSharedTopTileController
                            itemSearch={itemSearch}
                            categoryTiles={<SbRespCategoryTiles itemSearch={itemSearch} />}
                            designTiles={<SbRespDesignTiles itemSearch={itemSearch} />}
                            tradeOnApprovalBanner={
                                /* FINDING-13641 Workaround until React Suspense supports server-side
                                 * rendering > https://reactjs.org/docs/code-splitting.html#reactlazy
                                 */
                                isClient ? (
                                    <Suspense fallback={<SbRespTradeOnApprovalLoadingBanner />}>
                                        <SbRespTradeOnApprovalPromoBannerLazy
                                            itemSearch={itemSearch}
                                            viewer={viewer}
                                        />
                                    </Suspense>
                                ) : (
                                    <SbRespTradeOnApprovalLoadingBanner />
                                )
                            }
                            priceTiles={
                                <SbRespPriceTiles itemSearch={itemSearch} viewer={viewer} />
                            }
                            viewer={viewer}
                        />
                        <SbRespMainContent
                            currency={currency}
                            itemSearch={itemSearch}
                            viewer={viewer}
                            user={user}
                            isClient={isClient}
                            isThinPage={isThinPage}
                            hideSaveSearchButton={showTopSaveSearchButton}
                        />

                        {/* Related Searches With Title */}
                        <SbSharedRelatedSearches itemSearch={itemSearch} showTitle />

                        {/* Follow Search */}
                        {isClient && (
                            <SbRespFollowSearchBannerLazy
                                itemSearch={itemSearch}
                                user={user}
                                buttonSize="large"
                            />
                        )}

                        {/* Bottom Module */}
                        <SharedBottomModule
                            className={styles.bottomModule}
                            bottomModule={bottomModule}
                        />

                        {/* Ad */}
                        {!isThinPage && (
                            <SbSharedBillboardAd itemSearch={itemSearch} adWidth="desktop" />
                        )}
                        {/* Related Creators for creator pages */}
                        {isRelatedCreatorsModuleEnabled && (
                            <SbSharedRelatedCreators
                                itemSearch={itemSearch}
                                revealCreatorCount={REVEAL_RELATED_CREATORS_COUNT_DESKTOP}
                            />
                        )}
                        {/* Questions & Answers */}
                        <SharedFAQ itemSearch={itemSearch} />
                        {/* Interiors and/or Editorial */}
                        <SbRespInteriorsWordpressArticlesWrapper
                            itemSearch={itemSearch}
                            viewer={viewer}
                            user={user}
                            isClient={isClient}
                        />
                        {isSearch && isThinPage && (
                            <div className={styles.container}>
                                {/* Recommended Items */}
                                <SbSharedRecommendedItems viewer={viewer} />
                            </div>
                        )}
                        {/* Recently Viewed Items */}
                        <RecentlyViewedItemsLazy
                            centerTitle
                            wrapperClassName={styles.recentlyViewed}
                            authModalLoader={authModalLoader}
                            currency={currency}
                        />
                        {/*  Related Pages */}
                        <SharedRelatedPages
                            viewer={viewer}
                            itemSearch={itemSearch}
                            LinksBlockComponent={SharedRespLinksBlock}
                        />
                        {/*  More Ways to Browse */}
                        <SharedRespLinksBlock
                            viewer={viewer}
                            item={null}
                            itemSearch={itemSearch}
                            links={internalBuyLinks}
                        />
                    </>
                )}
            </SbSharedControlledHeadingWrapper>

            {/* Unavailable Item Modal */}
            <SbRespNotificationUnavailableItem />
            {/* Personalized Filter Toast */}
            <SbSharedPersonalizedFilterToast shippingFilters={shippingFilters} />
        </SharedSaveSearchContextProvider>
    );
}

SbRespComponentsComponent.propTypes = {
    itemSearch: PropTypes.object.isRequired,
    user: PropTypes.object,
    isClient: PropTypes.bool,
    viewer: PropTypes.object,
    generatedUriRef: PropTypes.string,
    relay: PropTypes.object.isRequired,
    internalBuyLinks: PropTypes.object,
    relayVariables: PropTypes.shape({
        isTrade: PropTypes.bool,
    }).isRequired,
    shippingFilters: PropTypes.array,
};

const mapStateToProps = state => {
    const { filters, relayVariables } = state;
    const { generatedUriRef, refetchInFlight } = filters;

    return {
        refetchInFlight,
        generatedUriRef,
        relayVariables: relayVariables.variables,
    };
};

export const SbRespComponents = createFragmentContainer(
    connect(mapStateToProps)(SbRespComponentsComponent),
    {
        itemSearch: graphql`
            fragment SbRespComponents_itemSearch on ItemSearchQueryConnection {
                totalResults
                pageType
                isL1LandingPage
                bottomModule {
                    ...SharedBottomModule_bottomModule
                }
                ...SbSharedControlledHeadingWrapper_itemSearch
                ...SbSharedForceSearchLink_itemSearch
                ...SbSharedSearchSuggestions_itemSearch
                ...SbRespNullSearchResults_itemSearch
                ...SbSharedPageBreadcrumbs_itemSearch
                ...SbSharedTitle_itemSearch
                ...SbSharedTopTileController_itemSearch
                ...SbSharedRelatedSearches_itemSearch
                ...SbRespCategoryTiles_itemSearch
                ...SbRespDesignTiles_itemSearch
                ...SharedSaveSearchContext_itemSearch
                ...SbSharedBillboardAd_itemSearch
                ...SbRespPriceTiles_itemSearch @include(if: $isTrade)
                ...SbRespTradeOnApprovalPromoBanner_itemSearch @include(if: $isTrade)
                ...SbRespMainContent_itemSearch
                ...SbRespContentModuleSelector_itemSearch
                ...SbRespInteriorsWordpressArticlesWrapper_itemSearch
                ...SharedWordpressArticles_itemSearch
                ...SharedFollowTopButton_itemSearch
                ...SbRespL1Landing_itemSearch
                appliedFilters {
                    name
                    values {
                        urlLabel
                    }
                }
                ...SharedRelatedPages_itemSearch
                ...SbSharedRelatedCreators_itemSearch
                ...SharedRespLinksBlock_itemSearch
                internalBuyLinks {
                    ...SharedRespLinksBlock_links
                }
                ...SharedFAQ_itemSearch
                ...SharedRecentSales_itemSearch
                soldItems: soldResults(first: 28) {
                    totalResults
                }
                ...SharedYMAL_itemSearch @arguments(priceBookName: $priceBookName)
                ...SbRespFollowSearchBanner_itemSearch
            }
        `,
        user: graphql`
            fragment SbRespComponents_user on User {
                ...TradePromo_user @include(if: $isTrade)
                ...SbRespNullSearchResults_user
                ...SbRespFollowSearchBanner_user
                ...SharedFollowTopButton_user
                ...SbSharedTitle_user
                ...SbSharedPageBreadcrumbs_user
                ...SharedSaveSearchContext_user
                ...SbRespMainContent_user
                ...SbRespInteriorsWordpressArticlesWrapper_user
                ...SharedRecentSales_user
                ...SharedYMAL_user
                ...SbRespContentModuleSelector_user
            }
        `,
        viewer: graphql`
            fragment SbRespComponents_viewer on Viewer {
                isRelatedCreatorsModuleEnabled: featureFlag(feature: "relatedCreatorsModule")
                ...SharedRelatedPages_viewer
                ...SharedRespLinksBlock_viewer
                ...SbRespNullSearchResults_viewer
                ...SbRespPriceTiles_viewer @include(if: $isTrade)
                ...SbRespMainContent_viewer
                ...SbRespTradeOnApprovalPromoBanner_viewer @include(if: $isTrade)
                ...SbSharedTopTileController_viewer
                ...SbRespInteriorsWordpressArticlesWrapper_viewer
                ...SbSharedRecommendedItems_viewer
                ...SharedRecentSales_viewer
            }
        `,
        shippingFilters: graphql`
            fragment SbRespComponents_shippingFilters on ShippingFilterType @relay(plural: true) {
                ...SbSharedPersonalizedFilterToast_shippingFilters
            }
        `,
    }
);
