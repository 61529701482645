import { useRef, useEffect } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'dibs-react-intl';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { Link } from 'dibs-elements/exports/Link';
import {
    trackSearchCorrectionClick,
    trackAutoCorrectClick,
    trackAutoCorrectView,
    encodeForceSearchTrackingData,
    ATTRIBUTE_SEARCH_CORRECTION,
    ATTRIBUTE_SEARCH_AUTO_CORRECT,
} from './sbSharedForceSearchLinkTracking';
import { pageTypeConstants as pageTypes } from '../../constants/pageTypeConstants';

// components
import { SbSharedSearchSuggestions } from '../SbSharedSearchSuggestions/SbSharedSearchSuggestions';

// styles
import styles from './SbSharedForceSearchLink.scss';

export const SbSharedForceSearchLinkComponent = ({ itemSearch }) => {
    const { searchCorrections = {}, pageType, searchTerm } = itemSearch;
    const { forceSearchUri, originalSearchTerm, semanticSearchTerm, classificationSearchTerm } =
        searchCorrections;
    const trackingRef = useRef({ originalSearchTerm, searchTerm, semanticSearchTerm });

    useEffect(() => {
        const correctedTerm =
            get(trackingRef, 'current.searchTerm') ||
            get(trackingRef, 'current.semanticSearchTerm');
        const originalTerm = get(trackingRef, 'current.originalSearchTerm');

        if (originalTerm && correctedTerm) {
            trackAutoCorrectView(correctedTerm, originalTerm);
        }
    }, []);

    const overrideType = pageType === pageTypes.SEARCH ? 'classification' : 'semantic';

    if (originalSearchTerm && !(semanticSearchTerm || classificationSearchTerm)) {
        return (
            <span
                data-tn="force-search-link-spell-correct"
                className={styles.link}
                {...{
                    [ATTRIBUTE_SEARCH_AUTO_CORRECT]: encodeForceSearchTrackingData({
                        term: searchTerm,
                        originalSearchTerm,
                    }),
                }}
            >
                <FormattedMessage
                    id="sb.SbSharedForceSearchLink.spellCorrectText"
                    defaultMessage={'Search instead for "{searchTerm}"'}
                    values={{
                        searchTerm: (
                            <Link
                                href={forceSearchUri}
                                onClick={() =>
                                    trackAutoCorrectClick(searchTerm, originalSearchTerm)
                                }
                            >
                                {originalSearchTerm}
                            </Link>
                        ),
                    }}
                />
            </span>
        );
    } else if (semanticSearchTerm || classificationSearchTerm) {
        const term = originalSearchTerm || semanticSearchTerm || classificationSearchTerm;
        return (
            <span
                data-tn="force-search-link-semantic"
                className={styles.link}
                {...{
                    [ATTRIBUTE_SEARCH_CORRECTION]: encodeForceSearchTrackingData({
                        term,
                        originalSearchTerm,
                        overrideType,
                    }),
                }}
            >
                <FormattedMessage
                    id="sb.sharedForceSearchLink.semantic"
                    defaultMessage='Search for "{searchTerm}" in all categories'
                    values={{
                        searchTerm: (
                            <Link
                                href={forceSearchUri}
                                onClick={() => trackSearchCorrectionClick(term, overrideType)}
                            >
                                {term}
                            </Link>
                        ),
                    }}
                />
            </span>
        );
    } else {
        return (
            <SbSharedSearchSuggestions
                searchTerm={searchTerm}
                itemSearch={itemSearch}
                className={styles.link}
            />
        );
    }
};
SbSharedForceSearchLinkComponent.propTypes = {
    itemSearch: PropTypes.object.isRequired,
};

export const SbSharedForceSearchLink = createFragmentContainer(SbSharedForceSearchLinkComponent, {
    itemSearch: graphql`
        fragment SbSharedForceSearchLink_itemSearch on ItemSearchQueryConnection {
            pageType
            searchTerm
            searchCorrections {
                forceSearchUri
                originalSearchTerm
                semanticSearchTerm
                classificationSearchTerm
            }
            ...SbSharedSearchSuggestions_itemSearch
        }
    `,
});
